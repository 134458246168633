import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/lehuaka",
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/box.vue"),
    meta: { title: "自述文件" },
    children: [
      {
        path: "/lehuaka",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/lexiangehua.vue"),
        meta: { title: "花优米" },
      },
      {
        path: "/yinsi",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/xieyi.vue"),
        meta: { title: "花优米" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
